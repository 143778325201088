import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Yash Narnaware`}</h1>
    <h2>{`aka `}<a parentName="h2" {...{
        "href": "/"
      }}>{`@yashify`}</a>{` on internet 😎`}</h2>
    <p>{`Who am I ? Hmm... basically an Absurd Nihilist 👤`}</p>
    <p>{`What I do ? Write Code 👨‍💻`}</p>
    <p>{`What Else ? Gotta find yourself 🙃`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      