import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import SpotifyPlayer from "../plugins/SpotifyPlayer";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Visit my blog site `}<a parentName="h2" {...{
        "href": "https://journal.yashify.in"
      }}>{`here`}</a>{`, below are some of the articles I have written`}</h2>
    <ProjectCard title="NodeJS In-memory Cache" link="https://journal.yashify.in/node-js/in-memory-cache/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This blog elaborates how to use in-memory cache in Node JS.
    </ProjectCard>
    <ProjectCard title="SSH Private Instance" link="https://journal.yashify.in/linux/ssh-using-jump-server/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  This article provides insight on access of private servers using jump server.
    </ProjectCard>
    <h2>{`Since you are here try couple of my favorite spotify playlist`}</h2>
    <ProjectCard title="Classic EDM's" link="https://open.spotify.com/embed/playlist/5OuwluOQwdzeTMc3ZqTHcI" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  <SpotifyPlayer code="5OuwluOQwdzeTMc3ZqTHcI" size="compact" theme="" view="list" mdxType="SpotifyPlayer" />
    </ProjectCard>
    <ProjectCard title="My On Repeats" link="https://open.spotify.com/playlist/37i9dQZF1EpxtXEXaMbeFN?si=ea8c9ad765554d47" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  <SpotifyPlayer code="37i9dQZF1EpxtXEXaMbeFN" size="compact" theme="0" view="list" mdxType="SpotifyPlayer" />
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      