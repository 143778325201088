import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am a software developer who likes to learn new things and solve problems.
My interest is in Linux and Web Technology as well as Clouds services.
My technical skill is development using Node, PHP, React, Mongo and many more...`}</p>
    </blockquote>
    <p>{`I pursue to become a Software Architect, but first I want to learn all the aspect of software development.
😃`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      