import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Whats More...`}</h2>
    <p>{`I have started writing some articles you can access by visiting `}<a parentName="p" {...{
        "href": "https://journal.yashify.in"
      }}>{`journal.yashify.in`}</a></p>
    <p>{`I will proressively post new articles, if you like my content then have a watch over my blog site.
I am planning to elaborate some problems I faced and how I overcame them, my learnings and some content related to philosophical concepts. 🍀`}</p>
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "/"
      }}>{`Hi`}</a>{` or find me on other platforms:
`}<a parentName="p" {...{
        "href": "https://github.com/yashify"
      }}>{`Github`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/yash-narnaware-435237168"
      }}>{`LinkedIn`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.instagram.com/yashify"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      