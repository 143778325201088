/** @jsx jsx */
/**
 * Spotify player iframe widget
 *
 * @author Alexander Wallin <office@alexanderwallin.com>
 * @see https://developer.spotify.com/technologies/widgets/spotify-play-button/
 */

//  import React from "react"
 import { jsx } from "theme-ui"

 // Size presets, defined by Spotify
 const sizePresets = {
   large: {
     width: 370,
     height: 380,
   },
   compact: {
     width: 370,
     height: 180,
   },
 }
 
 const SpotifyPlayer = ({ code, uri, view, theme, size }: { code: string;uri: string; view: string; theme: string; size: string; }) => (
		<iframe
			title="Spotify"
			className="SpotifyPlayer"
			src={`https://open.spotify.com/embed/playlist/${code}??utm_source=generator&theme=${theme}`}
			width={sizePresets[size].width}
			height={sizePresets[size].height}
			frameBorder="0"
			allowtransparency="true"
			allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"
		/>

 )
 
 export default SpotifyPlayer